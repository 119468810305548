<template>
  <div id="projectIndividual" v-if="project">
    <a :href="project.attributes.video_link" id="videoCont" v-if="project.attributes.video_link" class="d-flex flex-row align-items-center justify-content-center"
     :style="{backgroundImage:'url(\''+ base_url + '/' + project.attributes.feature_image.data.attributes.url +'\')'}">
      <div target="_blank" class="playBtn"></div>
    </a>
    <div id="videoCont" v-if="!project.attributes.video_link" class="d-flex flex-row align-items-center justify-content-center"
       :style="{backgroundImage:'url(\''+ base_url + '/' + project.attributes.feature_image.data.attributes.url +'\')'}">
    </div>
    <div class="container">
<!--      <section class="row mb-0">-->
<!--        <div class="col-12">-->
<!--          <ol class="breadcrumb">-->
<!--            <li class="breadcrumb-item" aria-current="page">-->
<!--              <router-link :to="{name: 'Projects', params: {type: type.toLowerCase()}}">Projects ({{ this.type }})</router-link>-->
<!--            </li>-->
<!--            <li class="breadcrumb-item active" aria-current="page">-->
<!--              {{ project.title }}-->
<!--            </li>-->
<!--          </ol>-->
<!--        </div>-->
<!--      </section>-->
      <section class="row mt-4">
        <div class="col-12 col-md-7">
          <div class="row">
            <div class="col-12">
              <carousel class="projectSlider"
                        :adaptiveHeight="true"
                        :arrows="false" :dots="true">
                <img
                    :key="imageIndex"
                    :src="photo"
                    @click="index = imageIndex"
                    v-for="(photo, imageIndex) in galleryImages"/>
              </carousel>
            </div>
            <div class="col-12">
              <div class="row" id="thumbCont">
                <div class="col-3 col-md-2 mt-3 thumbs" :key="imageIndex"
                     @click="index = imageIndex"
                     v-for="(photo, imageIndex) in galleryImages">
                  <img class="img-fluid" :src="photo"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="projectDetails">
            <h2 class="mb-4">{{ project.attributes.title }}</h2>
            <p>
              {{ project.attributes.description }}
            </p>
            <ul class="features">
              <li><span class="feature">Type of Project</span>
                <span :key="type" v-for="(type, index) in project.attributes.type.split(', ')">
                {{ type }}<span v-if="index !== project.attributes.type.length">,</span>
              </span>
              </li>
              <li><span class="feature">Year of Completion</span> {{ project.attributes.completion_year }}</li>
              <li><span class="feature">Designer</span> {{ project.attributes.designer }}</li>
              <li><span class="feature">Space</span> {{ project.attributes.space }}</li>
            </ul>
          </div>
        </div>
      </section>
      <!--<section class="row" v-if="typeof project.testimonials !== 'undefined' && project.testimonials.length > 0">
        <h2 class="mb-4">Testimonials</h2>
        <testimonials :testimonials="project.testimonials"/>
      </section>-->
      <gallery :images="galleryImages" :index="index" @close="index = null"></gallery>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
//import Testimonials from "@/components/Testimonials";
import Gallery from "@/components/Gallery";

export default {
  name: 'ProjectIndividual',
  components: {
    //'testimonials': Testimonials,
    'carousel': VueSlickCarousel,
    'gallery': Gallery
  },
  computed: {
    galleryImages() {
      if (this.project) {
        return this.project.attributes.images.data.map(photo => {
          return this.base_url + photo.attributes.url
        })
      }
      return [];
    }
  },
  data () {
    return {
      index: null,
      project: null,
      type: null,
      base_url: process.env.VUE_APP_PUBLIC_STRAPI_API_URL,
    }
  },
  created() {
    this.type = this.$route.params.type

    console.log(this.type)
    //this.project = projects.projects.find(p => p.attributes.slug === this.$route.params.slug)

    this.axios.get(this.$hostname + '/api/projects?filters[slug][$eq]='+this.$route.params.slug+'&populate=*', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
    }).then(response => {
      /*
      if (response.data.data.length > 0) {
        this.project = response.data.data.attributes
        console.log(this.project)
        this.is_loading = false
      }
       */
      this.project = response.data.data[0];
      console.log(this.project.attributes.title)
    })

    /*
    if (!this.project) {
      this.$router.push({name: 'NotFound'})
    }
    */
  }
}
</script>
