<template>
  <div id="projects" class="container">
    <h1 class="title mb-4">Projects</h1>
    <div class="selectionLinks d-flex flex-row">
      <router-link class="nav-link"
                   active-class="active"
                   :to="{name: 'Projects', params: {type: 'residential'}}">Residential</router-link>
      <router-link class="nav-link"
                   active-class="active"
                   :to="{name: 'Projects', params: {type: 'commercial'}}">Commercial</router-link>
    </div>
    <section class="row">
      <!-- Loading -->
      <div class="text-center d-flex flex-column"
           v-if="is_loading">
        <h2 class="no-border"
            style="margin-top: 100px">
          Loading Projects
        </h2>
      </div>
      <div class="text-center d-flex flex-column"
           v-if="projectFiltered.length <= 0 && !is_loading">
        <h2 class="no-border"
            style="margin-top: 100px">
          More Projects Coming Soon
        </h2>
        <router-link :to="{name: 'Home'}">Return Home</router-link>
      </div>
      <!-- Project tiles -->
      <div class="col-md-6 col-12 mb-4"
           :key="project.title"
           v-for="project in projectFiltered">
         <div class="d-flex flex-column">
           <div class="projectTiles d-flex flex-row">
             <router-link :to="{name: 'ProjectIndividual', params: {type: $route.params.type, slug: project.attributes.slug}}"
                          class="left"
                          v-if="project.attributes.feature_image.data !== null"
                    :style="{backgroundImage:'linear-gradient(to right bottom, rgba(132, 132, 132, 0.2), rgba(132, 132, 132, 0.8)),url(\''+ base_url + '/' + project.attributes.feature_image.data.attributes.url +'\')'}">
               <div class="overlay">
                 <h4>{{ project.attributes.title }}</h4>
               </div>
             </router-link>

             <router-link :to="{name: 'ProjectIndividual', params: {type: $route.params.type, slug: project.attributes.slug}}"
                          class="left"
                          v-if="project.attributes.feature_image.data == null"
                          :style="{backgroundImage:'linear-gradient(to right bottom, rgba(132, 132, 132, 0.2), rgba(132, 132, 132, 0.8)),url(\''+ base_url + '/' + '' +'\')'}">
               <div class="overlay">
                 <h4>{{ project.attributes.title }}</h4>
               </div>
             </router-link>
             <div class="right" v-if="!isMobile">
               <carousel class="projectSlider"
                         :swipeToSlide="true"
                         v-if="typeof project.attributes.images.data !== 'undefined' &&  project.attributes.images.data.length > 0"
                         :arrows="false" :dots="true">
                   <div :key="imageIndex" class="carousel-item"
                        v-for="(photo, imageIndex) in project.attributes.images.data.slice(0, 3)">
                     <img :src="base_url + '/' + photo.attributes.url"/>
                   </div>
               </carousel>
             </div>
           </div><!--/.projectTiles-->
           <router-link :to="{name: 'ProjectIndividual', params: {type: $route.params.type, slug: project.attributes.slug}}"
                        class="d-flex flex-row justify-content-end my-3">
             <img src="/images/icons/arrow-projects.svg" width="20"/>
           </router-link>
         </div><!--/.d-flex flex-column-->
      </div>
    </section>
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
export default {
  name: 'Projects',
  metaInfo: {
    title: 'Projects'
  },
  components: {
    'carousel': VueSlickCarousel
  },
  data () {
    return {
      isMobile: false,
      projects: [],
      base_url: process.env.VUE_APP_PUBLIC_STRAPI_API_URL,
      is_loading: false
    }
  },
  computed: {
    projectFiltered () {
      return this.projects.filter((project) => {
        if (typeof project.attributes.type === 'undefined') return false
        return project.attributes.type
            .toLowerCase()
            .indexOf(this.$route.params.type.toLowerCase()) > -1
      })
    }
  },
  created () {
    this.is_loading = true
    this.isMobile =Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
    //this.projects = projects.projects

    this.axios.get(this.$hostname + '/api/projects?sort=createdAt:desc&populate=*', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
    }).then(response => {
      if (response.data.data.length > 0) {
        this.projects = response.data.data

        this.is_loading = false
      }

    })
  },
  mounted () {
  }
}
</script>
